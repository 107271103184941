/* Global styles */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

/* Resume styles */
.resume {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f8f9fa;
  color: #333;
}

h1, h2, h3 {
  margin-bottom: 1rem;
  color: #333;
}

p {
  margin-bottom: 1.5rem;
}

/* Skill Card Styles */
.skill-card {
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.skill-logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 1rem auto;
}

/* Company and Institution Logos */
.company-logo, .institution-logo {
  width: 80px;
  height: auto;
  object-fit: contain;
  margin: auto;
}

/* Card Titles and Text */
.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.card-text {
  font-size: 0.875rem;
  color: #555;
  margin-bottom: 0.5rem;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .resume {
    padding: 1rem;
  }

  .skill-card {
    padding: 0.5rem;
  }

  .skill-logo {
    width: 40px;
    height: 40px;
  }

  .company-logo, .institution-logo {
    width: 60px;
  }

  .card-title {
    font-size: 1.1rem;
  }

  .card-subtitle {
    font-size: 0.9rem;
  }

  .card-text {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .resume {
    padding: 0.5rem;
  }

  .skill-card {
    padding: 0.25rem;
  }

  .skill-logo {
    width: 30px;
    height: 30px;
  }

  .company-logo, .institution-logo {
    width: 50px;
  }

  .card-title {
    font-size: 1rem;
  }

  .card-subtitle {
    font-size: 0.8rem;
  }

  .card-text {
    font-size: 0.75rem;
  }
}
