.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #57745a;
    color: white;
    text-align: center;
    padding: 1rem 0;
    font-size: 1rem;
  }
  
  @media screen and (max-width: 960px) {
    .footer {
      padding: 0.5rem 0;
      font-size: 0.875rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .footer {
      padding: 0.25rem 0;
      font-size: 0.75rem;
    }
  }
  