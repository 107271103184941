@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

/* Home Section */
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
}

.home a {
  color: #000000;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  font-size: 1.5rem;
  position: relative; /* Needed for the after pseudo-element */
}

.home a:hover {
  color: #000000;
  text-decoration: none;
}

.home a:hover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: #000000;
  visibility: visible;
  transform: scaleX(1);
  transition: all 0.2s ease-in-out;
}

.home a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: #57745a;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
}

.home .text-center {
  border-bottom: 2px solid #57745a;
  padding: 3px;
  margin-top: 5px;
}

/* About and Contact Sections */
.about,
.contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: #ffffff;
  color: #000000;
}

.yellow {
  background-color: yellow;
}

/* Page Icon */
.page-icon {
  margin-left: 1rem;
  font-size: 100px;
}

/* Error Page */
#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Footer */
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #57745a;
  color: white;
  text-align: center;
}

/* Logo Class */
.logoclass {
  position: relative;
  float: left;
  width: 114px;
  height: 94px;
  display: block;
  content: ' ';
  position: absolute;
  bottom: 0;
  right: 0;
}

/* Media Queries */
@media screen and (max-width: 960px) {
  .home,
  .about,
  .contact {
    font-size: 2.5rem;
  }

  .page-icon {
    margin-left: 1rem;
    font-size: 80px;
  }

  .home a {
    font-size: 1.2rem; /* Adjust font size for smaller screens */
  }
}

/* Repository Logo */
.repo-logo {
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin: auto;
}
