* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  
  .navbar {
    background: #57745a;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 70px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    position: sticky; /* Ensure the Link itself is sticky */
    top: 0; /* Stick to the top of the nearest scrolling ancestor */
    z-index: 999; /* Adjust as needed based on your layout */
  }

  .navbarImageLogo {
    height: 60px; /* Example height */
    margin-bottom: 30px;
    margin-left: 30px;
    align-self: center;
  }

  .sticky-typography {
    padding: 5px; /* Padding for the typography */ 
    position: absolute; /* Position absolutely relative to parent */
    bottom: 0; /* Align to the bottom of the parent container */
    left: 0; /* Align to the left within the parent container */
    right: 0; /* Align to the right within the parent container */
    z-index: 998; /* Ensure it's below the image */
  }

  .logo-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    position: relative; /* Ensure relative positioning for children */
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }

  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid rgb(255, 255, 255);
    color: #fff;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }

  .nav-links:hover {
    color: #fff;

  }
  
  .activated {
    color: #57745a;
    background-color: #fff;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background:#57745a;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      color: black;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }

    .navbarImageLogo {
      height: 40px; /* Example height */
      margin-bottom: 30px;
      margin-left: 30px;
      align-self: center;
    }
  
    .sticky-typography {
      padding: 5px; /* Padding for the typography */ 
      position: absolute; /* Position absolutely relative to parent */
      bottom: 0; /* Align to the bottom of the parent container */
      left: 0; /* Align to the left within the parent container */
      right: 0; /* Align to the right within the parent container */
      z-index: 998; /* Ensure it's below the image */
      font-size: 1.5rem;
    }
  
    .logo-container {
      display: flex;
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center items horizontally */
      position: relative; /* Ensure relative positioning for children */
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  }